/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import "./UserPage.css";
import PageHeader from "./Components/PageHeader";
import Footer from "./Components/Footer";
import useGetCashData from "../hooks/useGetCashData";
import useGetCassinoLedgerBetsClient from "./hooks/Casino/useGetCassinoLedgerBetsClient";
import { dateTimeFormatter } from "../utils/dateFormatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

export default function UserMyLedger() {
  const uuid = sessionStorage.getItem("UUID");

  const { mutate: getCashData } = useGetCashData();
  const { mutate: getCassinoLedgerBetsClient } =
    useGetCassinoLedgerBetsClient();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(1);

  const [data, setData] = useState([]);

  const [ledgerBets, setLedgerBets] = useState([]);
  const [allLedgerBets, setAllLedgerBets] = useState([]);
  const [gameData, setGameData] = useState([]);
  const [cassinoLedgerDate, setCassinoLedgerDate] = useState("");
  const [cassinoFinal, setCassinoFinal] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setLedgerBets([]);
    setAllLedgerBets([]);
    setGameData([]);
    setCassinoLedgerDate("");
    setCassinoFinal(0);
    setValue(1);
    setOpen(false);
  };

  function roundToTwoDecimals(num) {
    return Math.round(num * 100) / 100;
  }

  const getCassinoLedgerBets = (date) => {
    const payload = {
      logged_user_id: uuid,
      cassino_date: date,
    };

    getCassinoLedgerBetsClient(payload, {
      onSuccess: (response) => {
        const data = response.data.data.sort((a, b) => {
          return +new Date(b.updatedAt) - +new Date(a.updatedAt);
        });

        const gameList = [];

        data.forEach((item) => {
          const existingIndex = gameList.findIndex(
            (el) => el.id === item.cassino_game.id
          );
          if (existingIndex === -1) {
            gameList.push(item.cassino_game);
          } else {
            return;
          }
        });

        setGameData(gameList);
        setAllLedgerBets(data);

        handleOpen();
      },
      onError: (error) => {
        console.log(error);
        console.log("error", error);
      },
    });
  };

  const handleChangeTab = (id) => {
    const filteredData = allLedgerBets.filter(
      (item) => item.cassino_game.id === id
    );
    setLedgerBets(filteredData);
  };

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: "user",
      logged_acc_type: "user",
    };

    getCashData(payload, {
      onSuccess: (response) => {
        //console.log("response", response);
        let updatedCashData = [];

        response.data.forEach((item) => {
          item.final = item.final * -1;
        });

        response.data.forEach((element) => {
          if (element.game != null) {
            const existingIndex = updatedCashData.findIndex(
              (el) => el.match_id === element.match_id
            );

            if (existingIndex !== -1) {
              if (element.final !== 0) {
                updatedCashData[existingIndex] = {
                  ...element,
                  balance:
                    updatedCashData[existingIndex].balance +
                    roundToTwoDecimals(element.final),
                  tableBalance: 0,
                };
              } else {
                updatedCashData[existingIndex] = {
                  ...element,
                  balance: updatedCashData[existingIndex].balance,
                  tableBalance: 0,
                };
              }
            } else {
              if (element.final !== 0) {
                updatedCashData.push({
                  ...element,
                  balance: roundToTwoDecimals(element.final),
                  tableBalance: 0,
                });
              } else {
                updatedCashData.push({
                  ...element,
                  balance: 0,
                  tableBalance: 0,
                });
              }
            }
          } else if (element.cassino_game != null) {
            const existingIndex = updatedCashData.findIndex(
              (el) => el.remarks === element.remarks
            );

            if (existingIndex !== -1) {
              if (element.final !== 0) {
                updatedCashData[existingIndex] = {
                  ...element,
                  balance:
                    updatedCashData[existingIndex].balance +
                    roundToTwoDecimals(element.final),
                  tableBalance: 0,
                };
              } else {
                updatedCashData[existingIndex] = {
                  ...element,
                  balance: updatedCashData[existingIndex].balance,
                  tableBalance: 0,
                };
              }
            } else {
              if (element.final !== 0) {
                updatedCashData.push({
                  ...element,
                  balance: roundToTwoDecimals(element.final),
                  tableBalance: 0,
                });
              } else {
                updatedCashData.push({
                  ...element,
                  balance: 0,
                  tableBalance: 0,
                });
              }
            }
          } else {
            updatedCashData.push({
              ...element,
              balance: roundToTwoDecimals(element.final),
              tableBalance: 0,
            });
          }
        });

        updatedCashData?.sort((a, b) => {
          return (
            new Date(b.game?.updatedAt || b.updatedAt) -
            new Date(a.game?.updatedAt || a.updatedAt)
          );
        });

        updatedCashData.reverse();

        for (let index = 0; index < updatedCashData.length; index++) {
          if (index === 0) {
            updatedCashData[index].tableBalance = roundToTwoDecimals(
              updatedCashData[index].balance
            );
          } else {
            updatedCashData[index].tableBalance =
              updatedCashData[index - 1].tableBalance +
              roundToTwoDecimals(updatedCashData[index].balance);
          }
        }

        updatedCashData.reverse();

        setData(updatedCashData);
      },
      onError: (error) => {
        console.log("error", error);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="position-relative userTheme bg-light">
        <div
          className="position-relative"
          style={{ paddingTop: "92px", paddingBottom: "48px" }}
        >
          <PageHeader title="LEDGER" />

          <div className="container-fluid table-responsive p-0">
            <table className="ledger table table-striped table-bordered table-hover text-uppercase">
              <thead style={{ background: "#0092ad" }}>
                <tr>
                  <td
                    height="35"
                    colSpan="6"
                    align="center"
                    bgcolor="#3b394a"
                    className="TeamCombo p-0"
                  >
                    <p
                      className="mb-0"
                      style={{
                        color: "#FFF",
                        fontFamily: "Verdana, Geneva, sans-serif",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                    >
                      MY LEDGER
                    </p>
                  </td>
                </tr>
              </thead>
              <thead style={{ backgroundColor: "#0092ad" }}>
                <tr>
                  <th style={{ width: "600px" }}>Match Name</th>
                  <th>Won</th>
                  <th>Lost</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={index}>
                      <td
                        className={
                          item.cassino_game ? "text-danger fw-semibold" : ""
                        }
                        style={{
                          width: "600px",
                          cursor: item.cassino_game ? "pointer" : "default",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          if (item.cassino_game) {
                            const date = item.remarks.split(": ")[1];
                            setCassinoLedgerDate(date);
                            setCassinoFinal(item.balance);
                            getCassinoLedgerBets(date);
                          }
                        }}
                      >
                        {item.game
                          ? item.game.name
                          : item.cassino_game
                          ? item.remarks
                          : "Cash Pay"}{" "}
                        (
                        {item.game
                          ? dateTimeFormatter(item.game.updatedAt)
                          : dateTimeFormatter(item.updatedAt)}
                        )
                      </td>
                      <td className="text-black text-bold">
                        {item?.balance > 0
                          ? roundToTwoDecimals(item?.balance)
                          : 0}
                      </td>
                      <td className="text-black text-bold">
                        {item?.balance < 0
                          ? roundToTwoDecimals(item?.balance)
                          : 0}
                      </td>
                      <td className={`text-bold text-black`}>
                        {roundToTwoDecimals(item.tableBalance)}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          marginTop: "20px",

          zIndex: 100,
        }}
      >
        <Footer />
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        className="t20CreateBetDialog"
      >
        <DialogTitle className="bebas-neue-regular p-4 pb-3 fs-1 lh-1 d-flex align-items-center justify-content-between">
          CASSINO BETS RECORDS {cassinoLedgerDate}
          <FontAwesomeIcon
            icon={faXmark}
            size="xs"
            style={{ color: "#2d3e50", cursor: "pointer" }}
            onClick={handleClose}
          />
        </DialogTitle>
        <DialogContent className="mukta-regular px-3 pb-0">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  {gameData &&
                    gameData.map((item, index) => (
                      <Tab
                        key={index}
                        label={item.name}
                        value={item.id}
                        onClick={(e) => {
                          e.preventDefault();
                          handleChangeTab(item.id);
                        }}
                      />
                    ))}
                </TabList>
              </Box>
              {gameData &&
                gameData.map((item, index) => (
                  <TabPanel
                    key={index}
                    value={item.id}
                    className="p-2"
                    style={{ background: "whitesmoke" }}
                  >
                    {ledgerBets.length > 0
                      ? ledgerBets.map((bet, index) => (
                          <div
                            className="mukta-regular col-12 bet-card mt-2 p-0"
                            key={index}
                          >
                            <div
                              className="col-12 row m-0"
                              style={{ padding: "10px" }}
                            >
                              <div className="col-4 text-left-cls p-0">
                                <div className="bet-level">Round Id</div>
                                <div className="bet-value">{bet.mid}</div>
                              </div>
                              <div className="col-2 text-left-cls p-0">
                                <div className="bet-level">Amount</div>
                                <div className="bet-value">{bet.amount}</div>
                              </div>
                              <div className="col-2 text-left-cls p-0">
                                <div className="bet-level">Rate</div>
                                <div className="bet-value">{bet.rate}</div>
                              </div>
                              <div className="col-2 text-left-cls p-0">
                                <div className="bet-level">Bet</div>
                                <div className="bet-value">{bet.bet}</div>
                              </div>
                              <div className="col-2 text-left-cls p-0">
                                <div className="bet-level">Result</div>
                                <div className="bet-value">
                                  {bet.result.toUpperCase()}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </TabPanel>
                ))}
            </TabContext>
          </Box>
          <div
            className={`mt-1 mukta-regular text-center fw-bold fs-6 p-2 ${
              cassinoFinal > 0 ? "text-primary" : "text-danger"
            }`}
            style={{ background: "#f6f5f7" }}
          >
            {cassinoFinal > 0 ? "WON Coins" : "LOST Coins"}: {cassinoFinal}
          </div>
        </DialogContent>
        <DialogActions className="mukta-regular justify-content-center p-4 pt-3">
          <button
            className="btn btn-primary btn-lg m-0 rounded-3 w-50"
            onClick={handleClose}
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}
