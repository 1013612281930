/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./PageHeader.css";
import { useNavigate, Link } from "react-router-dom";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import useGetWalletBalance from "../hooks/useGetWalletBalance";
import Sidebar from "./SideBar";
import "./Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import JmdLogo from "../../img/jmdlogo.png";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import useSocketUserdata from "../hooks/Sockets/useSocketUserdata";

export default function PageHeader(props) {
  const navigate = useNavigate();
  // let token = localStorage.getItem("token");
  let userId = "";
  userId = sessionStorage.getItem("UUID");
  const userPassword = sessionStorage.getItem("password");
  const [data, setData] = useState([]);

  const socketData = useSocketUserdata(userId);

  useEffect(() => {
    if (socketData) {
      if (
        socketData.user.status === false ||
        socketData.user.status === "false" ||
        socketData.user.status === "0" ||
        socketData.user.status === 0
      ) {
        localStorage.clear();
        sessionStorage.clear();
        navigate("/");
      } else if (userPassword !== socketData.user.password) {
        localStorage.clear();
        sessionStorage.clear();
        navigate("/");
      } else {
        setData(socketData.user);
        if (props?.setCassinoData) {
          props?.setCassinoData(socketData.cassino);
          props?.setUserData(socketData.user);
        }
        if (props?.setGameData && props?.setUserData) {
          if (props?.game === "Teen Patti") {
            props?.setGameData(socketData.cassino[1]);
            props?.setUserData(socketData.user);
          } else if (props?.game === "Andar Bahar") {
            props?.setGameData(socketData.cassino[0]);
            props?.setUserData(socketData.user);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketData]);

  const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    toast.success("Logout Successfully");
    setTimeout(() => {
      navigate("/");
      window.location.reload();
    }, 1000);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to manage dropdown visibility

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown on icon click
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div
      style={{
        top: 0,
        zIndex: 100,
        position: "fixed",
        width: "100vw",
      }}
    >
      <table
        width="100%"
        border="0"
        cellSpacing="0"
        cellPadding="0"
        style={{
          backgroundColor: "#0092ad",
          height: "65px",
        }}
      >
        <tbody>
          <tr>
            <td width="0" className="pl-4" align="center">
              <div style={{ display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon
                  icon={faBars}
                  size="2xl"
                  className="pr-4"
                  style={{ color: "#ffffff" }}
                  onClick={toggleSidebar}
                />
                <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

                <Link to="/MainMenu">
                  <img
                    src={JmdLogo}
                    alt="Logo"
                    style={{ width: "140px", height: "45px" }}
                    id="jmdLogo"
                  />
                </Link>
              </div>
            </td>
            <td
              style={{ verticalAlign: "end" }}
              className="FontTextWhite"
              align="end"
            >
              <div className="profile_picture d-flex flex-column align-items-end">
                <p
                  align="end"
                  className="mb-1 pr-4 pt-1 d-flex justify-content-between"
                  id="coinsContainer"
                >
                  <span> Coins: {data ? Math.round(data.limit) : 0}</span>

                  <span id="coinsContainer1">
                    Used Coins : {props.usedCoins ? props.usedCoins : 0}
                  </span>
                </p>
                <div className="mb-1 pr-4" style={{ fontSize: "14px" }}>
                  <FontAwesomeIcon icon={faUser} /> : {data ? data.code : ""}{" "}
                  <div
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <button
                      onClick={toggleDropdown}
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        cursor: "pointer",
                        padding: "0",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        style={{ paddingLeft: "5px" }}
                        size="lg"
                      />
                    </button>

                    {isDropdownOpen && (
                      <div
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: "-150px",
                          right: "0", // Align with the parent width
                          // minWidth: "0px", // Limit the width if necessary
                          backgroundColor: "#0092ad",
                          border: "1px solid #ffff",
                          borderRadius: "5px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          zIndex: 100,
                          overflow: "hidden",
                          boxSizing: "border-box", // Include padding and border in width
                        }}
                      >
                        <Link to="/user-password">
                          <button
                            style={{
                              width: "100%",
                              padding: "10px",
                              textAlign: "left",
                              backgroundColor: "transparent",
                              border: "none",
                              cursor: "pointer",
                              color: "white",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faArrowRightFromBracket}
                              style={{ color: "#ffffff", paddingRight: "5px" }}
                              size="lg"
                            />
                            Change Password
                          </button>
                        </Link>
                        <button
                          style={{
                            width: "100%",
                            padding: "10px",
                            textAlign: "left",
                            backgroundColor: "transparent",
                            border: "none",
                            cursor: "pointer",
                            color: "white",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            logout();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faLock}
                            size="lg"
                            style={{ color: "#ffffff", paddingRight: "10px" }}
                          />
                          Logout
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </td>
            {/* <td
              width="55"
              align="center"
              className="FontTextWhite"
              style={{ padding: "8px" }}
            >
              <span style={{ cursor: "pointer" }} onClick={logout}>
                <img src={LGTop} alt="" width="40" height="40" />

                <span>Logout</span>
              </span>
        
            </td> */}
          </tr>
        </tbody>
      </table>

      <div
        className="welcomeMarquee"
        style={{
          backgroundColor: "#01667a",
          height: "30px",
        }}
      >
        <marquee style={{ fontSize: "14px" }}>
          🕉️🔱🔱Welcome to jmd10.com 🏆🏆
        </marquee>
      </div>
    </div>
  );
}
