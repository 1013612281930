import React from "react";
import { Routes, Route } from "react-router-dom";
import "../App.css";
import "react-toastify/dist/ReactToastify.css";
///////////////control panel ////////
import ControlLogin from "../ControlPanel/ControlLogin";
// import PrivateRoute from "./PrivateRoute";
import AllMatches from "../ControlPanel/AllMatches";
///////////////user control panel ////////
import UserLogin from "../ClientPanel/UserLogin";

import CasinoAllBetComplete from "../ClientPanel/CasinoAllBetComplete";
import UserInplay from "../ClientPanel/UserInplay";
import UserCompletegame from "../ClientPanel/UserCompletegame";
import UserMyLedger from "../ClientPanel/UserMyLedger";
import UserProfile from "../ClientPanel/UserProfile";
import UserCasino from "../ClientPanel/UserCasino";
import InPlayList from "../ControlPanel/InPlay";
import SessionInplay from "../ControlPanel/SessionInplay";
import SessionMinMaxLimit from "../ControlPanel/SessionMinMaxLimit";
import SessionDesscion from "../ControlPanel/SessionDesscion";
import ClickSingleSessionDesscion from "../ControlPanel/ClickSingleSessionDesscion";
import RepeatSessionDesscion from "../ControlPanel/RepeatSessionDesscion";
import InternalRepeatSession from "../ControlPanel/InternalRepeatSession";
import MatchDesscion from "../ControlPanel/MatchDesscion";
import CompleteSports from "../ControlPanel/CompleteSports";
import ViewDesicion from "../ControlPanel/ViewDesicion";
import AddMatchDescription from "../ControlPanel/AddMatchDescription";
import EditMatchDescription from "../ControlPanel/EditMatchDescription";
import UserBet from "../ClientPanel/UserBet";
import Changepassword from "../ClientPanel/Changepassword";
import Welcome from "../ClientPanel/Welcome";
import MainMenu from "../ClientPanel/MainMenu";
import UserCompletegamelist from "../ClientPanel/UserCompletegamelist";
import NotFound404 from "../Components/NotFound404";
import CasinoBetPageTeenPatti from "../ClientPanel/Casino/CasinoBetPageTeenPatti";
import CasinoBetPageAnderBhar from "../ClientPanel/Casino/CasinoBetPageAnderBhar";
import CasinoTeenPatiComplete from "../ClientPanel/CompleteBets/CasinoTeenPatiComplete";
import CasinoAnderBharComplete from "../ClientPanel/CompleteBets/CasinoAnderBharComplete";
import UserLogin2 from "../ClientPanel/UserLogin2";

const AppRoutes = () => {
  const renderRoute = (element) => {
    if (localStorage.getItem("token") && sessionStorage.getItem("status")) {
      return element;
    } else {
      if (window.location.pathname !== "/") {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/";
      }
    }
  };

  return (
    <Routes>
      {/* ////////login panel //////////// */}
      <Route exact path="/2" element={<UserLogin />} />
      <Route exact path="/" element={<UserLogin2 />} />
      <Route
        exact
        path="/user-ledger"
        element={renderRoute(<UserMyLedger />)}
      />
      <Route
        exact
        path="/casino-all-bets-complete"
        element={renderRoute(<CasinoAllBetComplete />)}
      />
      <Route
        exact
        path="/casino-bet-page-teen-patti"
        element={renderRoute(<CasinoBetPageTeenPatti />)}
      />
      <Route
        exact
        path="/casino-bet-page-andar-bahar"
        element={renderRoute(<CasinoBetPageAnderBhar />)}
      />
      <Route
        exact
        path="/casino-teen-patti-complete"
        element={renderRoute(<CasinoTeenPatiComplete />)}
      />
      <Route
        exact
        path="/casino-ander-bhar-complete"
        element={renderRoute(<CasinoAnderBharComplete />)}
      />
      <Route
        exact
        path="/user-complete-game"
        element={renderRoute(<UserCompletegame />)}
      />
      <Route
        exact
        path="/user-profile"
        element={renderRoute(<UserProfile />)}
      />
      <Route exact path="/user-inplay" element={renderRoute(<UserInplay />)} />
      <Route exact path="/user-casino" element={renderRoute(<UserCasino />)} />
      <Route
        exact
        path="/user-inplay/start-bet"
        element={renderRoute(<UserBet />)}
      />
      <Route
        exact
        path="/user-completegame-list"
        element={renderRoute(<UserCompletegamelist />)}
      />
      {/* //////////////////////////////////// */}
      <Route exact path="/welcome" element={renderRoute(<Welcome />)} />
      <Route exact path="/MainMenu" element={renderRoute(<MainMenu />)} />
      <Route
        exact
        path="/user-password"
        element={renderRoute(<Changepassword />)}
      />
      {/* ////////end user panel //////////// */}\
      {/* ////////control panel //////////// */}
      <Route exact path="/super-admin/login" element={<ControlLogin />} />
      <Route
        exact
        path="/super-admin/all-matches"
        element={renderRoute(<AllMatches />)}
      />
      <Route
        exact
        path="/super-admin/inplay-list"
        element={renderRoute(<InPlayList />)}
      />
      <Route
        exact
        path="/session_inplay/:gameId"
        element={renderRoute(<SessionInplay />)}
      />
      <Route
        exact
        path="/super-admin/Session-Min-Max-Limit"
        element={renderRoute(<SessionMinMaxLimit />)}
      />
      <Route
        exact
        path="/super-admin/session-desscion"
        element={renderRoute(<SessionDesscion />)}
      />
      <Route
        exact
        path="/super-admin/session-desscion/clickDesscion"
        element={renderRoute(<ClickSingleSessionDesscion />)}
      />
      <Route
        exact
        path="/super-admin/repeat-session-desscion"
        element={renderRoute(<RepeatSessionDesscion />)}
      />
      <Route
        exact
        path="/super-admin/repeat-session-desscion/InternalRepeatSession"
        element={renderRoute(<InternalRepeatSession />)}
      />
      <Route
        exact
        path="/super-admin/match-desscion"
        element={renderRoute(<MatchDesscion />)}
      />
      <Route
        exact
        path="/super-admin/complete-sports"
        element={renderRoute(<CompleteSports />)}
      />
      <Route
        exact
        path="/super-admin/view-desicion/:matchId"
        element={renderRoute(<ViewDesicion />)}
      />
      <Route
        exact
        path="/super-admin/add-match-description"
        element={renderRoute(<AddMatchDescription />)}
      />
      <Route
        exact
        path="/super-admin/add-match-description/EditMatchDesctiption"
        element={renderRoute(<EditMatchDescription />)}
      />
      {/* <Route exact path="/" element={renderRoute(<MainLayout />)}> */}
      <Route exact path="*" element={<NotFound404 />} />
      <Route exact path="/404" element={<NotFound404 />} />
      {/* </Route> */}
    </Routes>
  );
};

export default AppRoutes;
