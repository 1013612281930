/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./casinoStyle.css";
import { Link, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import { throttle } from "lodash";

import PageHeader from "../Components/PageHeader";
import Footer from "../Components/Footer";
import useGetAB from "../hooks/Casino/useGetAB";
import useGetABResult from "../hooks/Casino/useGetABResult";
import useCreateT20Bet from "../hooks/Casino/useCreateT20Bet";
import useGetT20Bet from "../hooks/Casino/useGetT20Bet";
import useGetT20Position from "../hooks/Casino/useGetT20Position";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faLock } from "@fortawesome/free-solid-svg-icons";
import { timeFormatter } from "../../utils/dateFormatter";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ABRulesModal from "./ABRulesModal";

// Define throttled function outside the component
const throttledHandleGetT20Position = throttle(
  (handleGetT20Position, handleGetT20Bet) => {
    handleGetT20Position();
    handleGetT20Bet();
  },
  8000
);

export default function CasinoBetPageAnderBhar() {
  const BASE_URL = process.env.REACT_APP_CLOUDINARY_URL;
  const navigate = useNavigate();

  let userId = sessionStorage.getItem("UUID");

  const { mutate: getAB } = useGetAB();
  const { mutate: getABResult } = useGetABResult();
  const { mutate: createT20Bet } = useCreateT20Bet();
  const { mutate: getT20Bet } = useGetT20Bet();
  const { mutate: getT20Position } = useGetT20Position();

  const [currentTime, setCurrentTime] = useState("");
  const [playerBetData, setPlayerBetData] = useState([]);
  const [playerAData, setPlayerAData] = useState([]);
  const [playerBData, setPlayerBData] = useState([]);
  const [mid, setMid] = useState("");
  const [timer, setTimer] = useState("");
  const [card1, setCard1] = useState("1");
  const [card2, setCard2] = useState("1");
  const [card3, setCard3] = useState("1");
  const [card4, setCard4] = useState("1");
  const [card5, setCard5] = useState("1");
  const [card6, setCard6] = useState("1");
  const [cardMain, setCardMain] = useState("1");
  const [resultData, setResultData] = useState([]);
  const [betPayload, setBetPayload] = useState([]);
  const [betAmount, setBetAmount] = useState(0);
  const [t20BetData, setT20BetData] = useState([]);
  const [gameData, setGameData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [matchId, setMatchId] = useState("");

  const [playerAPosition, setPlayerAPosition] = useState(0);
  const [playerBPosition, setPlayerBPosition] = useState(0);

  const amountData = [100, 200, 500, 1000, 5000, 10000, 20000, 25000, 50000];

  const [open, setOpen] = useState(false);
  const [openRules, setOpenRules] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenRules = () => {
    setOpenRules(true);
  };

  const handleCloseRules = () => {
    setOpenRules(false);
  };

  const handleClose = () => {
    setBetAmount("0");
    setBetPayload([]);
    setOpen(false);
  };

  const handleCasinoAllBetComplete = () => {
    navigate("/casino-all-bets-complete");
  };

  const handleGetT20 = () => {
    getAB(undefined, {
      onSuccess: (response) => {
        let date = response.date;
        setCurrentTime(timeFormatter(date));

        setMid(response.data.data.t1[0].mid);
        setTimer(response.data.data.t1[0].autotime);

        let cards = response.data.data.t1[0].cards.split(",");

        setCardMain(cards[0]);

        const nonOneCards = cards.filter((card) => card !== "1");
        const last6NonOneCards = nonOneCards.slice(-6);

        if (nonOneCards.length < 8) {
          setCard1(nonOneCards[1] || "1");
          setCard2(nonOneCards[2] || "1");
          setCard3(nonOneCards[3] || "1");
          setCard4(nonOneCards[4] || "1");
          setCard5(nonOneCards[5] || "1");
          setCard6(nonOneCards[6] || "1");
        } else {
          //check if the lenght of nonOneCards is even
          if (nonOneCards.length % 2 === 0) {
            setCard2(last6NonOneCards[0]);
            setCard4(last6NonOneCards[2]);
            setCard6(last6NonOneCards[4]);
            setCard1(last6NonOneCards[1]);
            setCard3(last6NonOneCards[3]);
            setCard5(last6NonOneCards[5]);
          } else {
            setCard1(last6NonOneCards[0]);
            setCard2(last6NonOneCards[1]);
            setCard3(last6NonOneCards[2]);
            setCard4(last6NonOneCards[3]);
            setCard5(last6NonOneCards[4]);
            setCard6(last6NonOneCards[5]);
          }
        }

        setPlayerBetData(response.data.data.t2);

        setPlayerAData(response.data.data.t2[0]);
        setPlayerBData(response.data.data.t2[1]);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const handleGetT20Result = () => {
    getABResult(undefined, {
      onSuccess: (data) => {
        setResultData(data.data.data);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const handleCreateT20Bet = () => {
    const payload = {
      amount: betAmount,
      ...betPayload,
    };

    if (payload.mid !== mid) {
      toast.error("Invalid Match ID");
      return;
    }

    if (payload.bet === "A") {
      if (playerAData.gstatus !== "1") {
        toast.error("Bet Locked");
        return;
      }
    } else {
      if (playerBData.gstatus !== "1") {
        toast.error("Bet Locked");
        return;
      }
    }

    if (betAmount < 100) {
      toast.error("Minimum Bet Amount is 100");
      return;
    }

    if (betAmount > 25000) {
      toast.error("Maximum Bet Amount is 25000");
      return;
    }

    createT20Bet(payload, {
      onSuccess: (data) => {
        console.log(data);
        toast.success("Bet Placed Successfully");
        handleGetT20Bet();
        handleClose();
      },
      onError: (error) => {
        console.log(error);
        toast.error("Something went wrong");
      },
    });
  };

  const handleGetT20Bet = () => {
    const payload = {
      user_id: userId,
      match_id: gameData?.id,
    };

    getT20Bet(payload, {
      onSuccess: (data) => {
        setT20BetData(data.data.data);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const handleGetT20Position = () => {
    console.log("gameData", gameData);
    console.log("gameData?.id", gameData?.id);
    const payload = {
      user_id: userId,
      match_id: gameData?.id,
    };

    getT20Position(payload, {
      onSuccess: (data) => {
        let positionData = data.data.data;

        setPlayerAPosition(positionData.player_a);
        setPlayerBPosition(positionData.player_b);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  useEffect(() => {
    handleGetT20();
    handleGetT20Result();
    // handleGetT20Bet();
    // handleGetT20Position();

    const intervalId = setInterval(() => {
      handleGetT20();
    }, 800);

    const intervalId2 = setInterval(() => {
      handleGetT20Result();
      // handleGetT20Bet();
      // handleGetT20Position();
    }, 8000);

    return () => {
      clearInterval(intervalId);
      clearInterval(intervalId2);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (gameData?.isLocked === 1) {
      toast.error("This match is locked 1");
      navigate("/user-casino");
    }

    if (userData?.cassino_status !== true && userData?.length !== 0) {
      toast.error("This match is locked 2");
      navigate("/user-casino");
    }

    if (gameData?.id) {
      throttledHandleGetT20Position(handleGetT20Position, handleGetT20Bet);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameData, userData]);

  return (
    <>
      <div
        className="position-relative userTheme bg-light"
        style={{ overflowX: "hidden" }}
      >
        <div style={{ position: "sticky", top: 0, zIndex: 100 }}>
          <PageHeader
            title="LEDGER"
            game="Andar Bahar"
            setGameData={setGameData}
            setUserData={setUserData}
          />
        </div>
        <div className="menu" id="menu" align="center">
          <ul className="nav">
            <li className="active abc w-100">
              <Link to="/user-casino" style={{ height: "32.1px" }}>
                BACK TO CASINO MENU
              </Link>
            </li>
          </ul>
        </div>

        <div className="row align-items-start">
          <div className="col-md-8">
            {/* Markets */}
            <div className="markets">
              <div
                className="casino-heading d-flex justify-content-between align-items-center p-2 text-center"
                style={{
                  backgroundColor: "#2c3e50",
                  minHeight: "50px",
                }}
              >
                <span
                  className="d-flex align-items-center"
                  style={{
                    fontSize: "12px",
                    fontWeight: "700",
                    textTransform: "uppercase",
                    color: "white",
                  }}
                >
                  live Ander Bahar{""}
                  <FontAwesomeIcon className="mx-1" icon={faArrowRight} />
                  <span onClick={handleOpenRules}>RULES</span>
                </span>
                <span className="text-white" style={{ fontSize: "16px" }}>
                  Round ID: {mid}
                </span>
                <span className="text-white" style={{ fontSize: "16px" }}>
                  Time: {currentTime}
                </span>
              </div>

              <div className="score-panel">
                <div className="card-panel card-panel-ab p-4">
                  <div className="d-flex flex-column card-header-container">
                    <d3v className="card-header card-header-ab">A</d3v>
                    <div className="card-header card-header-ab">B</div>
                  </div>

                  <img
                    className="mx-2 main-card"
                    src={`${BASE_URL}/${cardMain}.webp`}
                    alt="Card Main"
                    width="28"
                    height="35"
                  />

                  <div className="d-flex flex-column gap-2">
                    <div className="cards-container">
                      <img
                        className="mr-2"
                        src={`${BASE_URL}/${card2}.webp`}
                        alt="Card 2"
                        width="28"
                      />
                      <img
                        className="mr-2"
                        src={`${BASE_URL}/${card4}.webp`}
                        alt="Card 4"
                        width="28"
                      />
                      <img
                        className="mr-2"
                        src={`${BASE_URL}/${card6}.webp`}
                        alt="Card 6"
                        width="28"
                      />
                    </div>

                    <div className="cards-container">
                      <img
                        className="mr-2"
                        src={`${BASE_URL}/${card1}.webp`}
                        alt="Card 1"
                        width="28"
                      />
                      <img
                        className="mr-2"
                        src={`${BASE_URL}/${card3}.webp`}
                        alt="Card 3"
                        width="28"
                      />
                      <img
                        className="mr-2"
                        src={`${BASE_URL}/${card5}.webp`}
                        alt="Card 5"
                        width="28"
                      />
                    </div>
                  </div>
                </div>
                <div className="bet-timer">{timer}</div>
                <iframe
                  title="Andar Bahar"
                  width="100%"
                  height="210"
                  style={{
                    border: "1px",
                    marginLeft: "-1px",
                    marginRight: "-1px",
                    marginTop: "0px",
                    marginBottom: "-5px",
                    // backgroundColor: "gray",
                  }}
                  src="https://video.247sitedata.xyz/route/?id=3043"
                ></iframe>
              </div>
            </div>

            {/* Session Bets */}
            <table className="mb-0 table-bordered table">
              <tbody>
                <tr className="text-black">
                  <th className="text-left" width="60%">
                    Min: 100 | Max: 25000{" "}
                  </th>
                  <th style={{ backgroundColor: "#72bbef" }}>BACK</th>
                </tr>

                <tr>
                  <td className="text-left align-middle text-md fw-bolder">
                    Andar
                    <span
                      className={`${
                        playerAPosition < 0 ? "text-danger" : "text-green"
                      } ml-2`}
                    >
                      ({playerAPosition})
                    </span>
                  </td>
                  <td
                    className={`h5 fw-bold playerBetBtn ${
                      playerAData?.gstatus === "1" ? "" : "disabled"
                    }`}
                    // className="h5 fw-bold playerBetBtn"
                    style={{
                      backgroundColor: "#72bbef",
                      position: "relative",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      const payload = {
                        user_id: userId,
                        match_id: gameData?.id,
                        bet: "A",
                        nation: "Andar",
                        rate: 0.98,
                        mid: playerAData.mid,
                        sid: playerAData.sid,
                        trash: false,
                        ledger_status: false,
                      };

                      setBetPayload(payload);
                      handleOpen();
                    }}
                  >
                    {playerAData?.gstatus === "1" ? null : (
                      <div className="bet-disabled">
                        <FontAwesomeIcon icon={faLock} />
                      </div>
                    )}
                    {playerBetData.length > 0 ? 1.98 : "N/A"}
                    <br />
                    <span className="h6">0</span>
                  </td>
                </tr>

                <tr>
                  <td className="text-left align-middle text-md fw-bolder">
                    Bahar
                    <span
                      className={`${
                        playerBPosition < 0 ? "text-danger" : "text-green"
                      } ml-2`}
                    >
                      ({playerBPosition})
                    </span>
                  </td>
                  <td
                    className={`h5 fw-bold playerBetBtn ${
                      playerBData?.gstatus === "1" ? "" : "disabled"
                    }`}
                    // className="h5 fw-bold playerBetBtn"
                    style={{
                      backgroundColor: "#72bbef",
                      position: "relative",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      const payload = {
                        user_id: userId,
                        match_id: gameData?.id,
                        bet: "B",
                        nation: "Bahar",
                        rate: 0.98,
                        mid: playerBData.mid,
                        sid: playerBData.sid,
                        trash: false,
                        ledger_status: false,
                      };

                      setBetPayload(payload);
                      handleOpen();
                    }}
                  >
                    {playerBData?.gstatus === "1" ? null : (
                      <div className="bet-disabled">
                        <FontAwesomeIcon icon={faLock} />
                      </div>
                    )}
                    {playerBetData.length > 0 ? 1.98 : "N/A"}
                    <br />
                    <span className="h6">0</span>
                  </td>
                </tr>
              </tbody>
            </table>

            {/* Results */}
            <div
              className="casino-heading d-flex justify-content-between align-items-center p-2 text-white"
              style={{
                backgroundColor: "#2c3e50",
                fontSize: "16px",
                lineHeight: "20px",
              }}
            >
              <span style={{ fontWeight: "500" }}>Last Result</span>
              <span className="fw-bold">View All</span>
            </div>
            <div
              className="d-flex align-items-center justify-content-end px-3 pb-2 mt-2"
              style={{ gap: "4px" }}
            >
              {resultData.length > 0
                ? resultData.map((result, index) => (
                    <span
                      key={index}
                      className={`rounded-circle text-center align-middle ${
                        result.result === 1 ? "bg-danger" : "bg-primary"
                      }`}
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        minWidth: "20px",
                        height: "20px",
                        lineHeight: "18px",
                      }}
                    >
                      {result.result === 1 ? "A" : "B"}
                    </span>
                  ))
                : "Loading..."}
            </div>
          </div>

          <div className="col-md-4 mt-0">
            <div
              className="casino-heading d-flex align-items-center p-2 text-white text-md fw-bold"
              style={{
                backgroundColor: "#2c3e50",
                minHeight: "50px",
              }}
            >
              MY BET
            </div>
            <table className="mb-0 table-bordered table">
              <thead>
                <tr style={{ backgroundColor: "#d7d7d7" }}>
                  <th>Matched Bet</th>
                  <th>Market</th>
                  <th>Odds</th>
                  <th>Stake</th>
                </tr>
              </thead>
              <tbody>
                {t20BetData.length > 0 ? (
                  t20BetData.map((data, index) => (
                    <tr key={index} style={{ backgroundColor: "#72BBEF" }}>
                      <td>
                        {data.nation} ({data.mid})
                      </td>
                      <td>Live20</td>
                      <td>{data.rate}</td>
                      <td>{data.amount}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No data found</td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className="text-center">
              <button
                className="btn btn-dark"
                onClick={() => {
                  handleCasinoAllBetComplete();
                }}
              >
                See all Completed bets
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",

          zIndex: 100,
        }}
      >
        <Footer />
      </div>

      <ABRulesModal open={openRules} onClose={handleCloseRules} />

      <Dialog open={open} onClose={handleClose} className="t20CreateBetDialog">
        <DialogTitle
          className="p-3 text-md fw-bold text-white"
          style={{
            backgroundColor: "#2d3e50",
          }}
        >
          PLACE BET
        </DialogTitle>
        <DialogContent className="p-0">
          <table className="table mb-0">
            <thead>
              <tr style={{ backgroundColor: "#d7d7d7" }}>
                <th width="20%">Bet For</th>
                <th width="20%">Odds</th>
                <th width="40%">Stake</th>
                <th width="20%">Profit</th>
              </tr>
            </thead>
            {betPayload ? (
              <tbody>
                <tr className="align-middle">
                  <td>{betPayload.nation}</td>
                  <td>{betPayload.rate}</td>
                  <td className="p-1">
                    <input
                      type="number"
                      className="form-control text-sm fw-semibold p-1 rounded-1"
                      style={{
                        height: "calc(1.75rem)",
                        width: "80%",
                        marginLeft: "0",
                      }}
                      value={betAmount > 0 ? betAmount : ""}
                      onChange={(e) => setBetAmount(e.target.value)}
                      placeholder="Enter Amount"
                    />
                  </td>
                  <td>{Math.round(betPayload.rate * betAmount)}</td>
                </tr>
              </tbody>
            ) : null}
          </table>
          <div
            className="d-flex align-items-center p-2 flex-wrap"
            style={{ gap: "4px" }}
          >
            {amountData.map((item, index) => (
              <button
                key={index}
                className="btn btn-secondary m-0 rounded-1"
                onClick={(e) => {
                  e.preventDefault();
                  setBetAmount(item);
                }}
              >
                {item}
              </button>
            ))}
          </div>
        </DialogContent>
        <DialogActions style={{ gap: "8px" }}>
          <button
            className="btn btn-danger m-0 rounded-1"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="btn btn-success m-0 rounded-1"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              handleCreateT20Bet();
            }}
          >
            Submit
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}
