import { useState } from "react";
import { io } from "socket.io-client";

const useGetWalletBalance = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [walletBalance, setWalletBalance] = useState(null);
  const [error, setError] = useState(null);

  const socket = io("http://localhost:4444");

  const getWalletBalance = (body, options = {}) => {
    setIsLoading(true);
    setError(null);
    socket.emit("wallet_balance", body);

    socket.on("wallet_balance_response", (response) => {
      setIsLoading(false);
      if (response.success) {
        setWalletBalance(response.data);
        if (options.onSuccess) options.onSuccess(response);
      } else {
        setError(response.error);
        if (options.onError) options.onError(response.error);
      }
    });
  };

  return { getWalletBalance, walletBalance, isLoading, error };
};

export default useGetWalletBalance;
